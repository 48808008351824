.form {
  display: flex;
  flex-direction: column;
  width: 20rem;
  max-width: 100%;
  text-align: left;

  .formControl {
    margin: 1rem 0;
  }

  .title {
    text-align: center;
  }
}

.paragraph {
  margin-top: 1rem;
}
