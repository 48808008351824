@import "styles/breakpoints.scss";

.container {
  display: flex;
  height: 100vh;

  .image {
    width: 50%;
    height: 100%;
    position: relative;
    // background: url('/images/login-image.jpg');
    // background-position: center;
    // background-repeat: no-repeat;
    // background-size: cover;
  }

  .logo {
    margin-bottom: 2rem;
    text-align: center;

    .tagline {
      margin-top: 0.5rem;
      font-size: 0.9rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;

    .form {
      display: flex;
      flex-direction: column;
      width: 20rem;
      max-width: 100%;
      text-align: left;
    }
  }

  .image {
    flex: 1;
  }
  .content {
    width: 40%;
    min-width: 30rem;
  }

  @include medium {
    .image {
      display: none;
    }
    .content {
      width: 100%;
    }
  }
}
