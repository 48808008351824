@import '../../styles/shared.scss';

.container {
  height: auto;
  // margin: 0 $MainContentAreaSideMargin;
  @include medium {
    margin: 0;
  }

  &.modalView {
    margin: 0;
  }
  position: relative;
}

.dataContainer {
  position: relative;
}

.emptyFolder {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center
}

.loadingWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropTarget {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;

  &.notWritable {
    background: rgba(96, 16, 32, 0.5)
  }
}

.dropTargetText {
  font-size: 200%;
  margin: 0 auto;
  color: white;
}
