@import '../../../styles/shared.scss';

.container {
  position: relative;
  height: auto;
}

.wrapper {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.thumbnail {
  width: 100%;
  height: 145px;
  border-radius: 2px;
}

.dateCreated {
  font-size: 14px;
}

.icons {
  margin-top: 0.5rem;
  padding-top: 2px;
  gap: 0.5rem;
  display: flex;
}

.size {
  height: 20px;
}

.sharedWith {
  height: 20px;
}
