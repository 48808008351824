$colorAction: #ff671b;
$colorActionHover: #d85615;
$colorError: #cc0033;
$colorGreyLight: #efefef;
$colorGreyMedium: #dddddd;
$colorGreyDark: #5c5c5c;
$colorPrimary: #333333;
$colorSecondary: #888888;
$colorWhite: #ffffff;
$colorSuccess: #8bc34a;
$colorBackground: #f8f8f8;

$colorLightOrange: #fbe7e0;
$colorGray-Upload: #f6f6f6;
$colorGray-UploadBorder: #dfdfdf;
$colorGray-UploadText: #5c5c5c;
$colorGray-Cancel: #d1d1d1;
