@import "styles/shared.scss";

.container, .containerActive {
  position: fixed;
  width: $sidebarWidth;
  height: 100%;
  background: $colorPrimary;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  left: -$sidebarWidth;
  @include desktop {
    left: 0;
  }

  @include medium {
    left: -$sidebarWidth;
  }

  transition: all $easeDuration $ease;

  z-index: 100;

  padding-bottom: calc($pageMargin - 15px);
}

.containerActive {
  left: 0;
}

.link, .linkActive {
  position: relative;
  color: $colorWhite;
  background-color: $colorPrimary;
  cursor: pointer;

  height: 40pt;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .icon {
    width: 23pt;
    margin-left: $pageMargin;
  }
  .text {
    margin: 0 13pt;
    text-transform: uppercase;
    letter-spacing: 0.55pt;
    font-size: 10pt;
    line-height: 14pt;
  }
}

.linkActive {
  color: $colorAction;
  background: rgba(0, 0, 0, 0.1);
  &::before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    content: '';
    border-left: 2px solid $colorAction;
  }
}

.logoWrapper {
  height: $headerHeight;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  .logo {
    margin-left: $pageMargin;
  }
}

.divider {
  flex-grow: 1;
  position: relative;
  &::after {
    position: absolute;
    bottom: 3pt;
    left: $pageMargin;
    right: $pageMargin;
    content: '';
    box-sizing: border-box;
    height: 2pt;
    border: solid 1pt rgba($colorSecondary, 0.2);
  }
}

.contact {
  width: 100%;
  height: 480px;
  margin: 0;
  padding: 0;
  border: none;
}
