@use 'styles/colors';

.container {
  height: auto;
  margin-top: 1pt;
  transform: scale(1.2) translate(8px, 0);
}

.group {
  fill: colors.$colorAction;
}
