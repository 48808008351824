@import 'styles/shared.scss';

.container {
  height: $MediaBrowserHeaderHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $MainContentAreaTopMargin;
  background: $colorLightOrange;
  padding: 12px;
  @include medium {
    //height: 50px;
    padding: 0 14px;
    margin-top: 0;
  }
  min-height: 40px;
}

.selectedCount {
  color: $colorAction;
}

.selectedCountWrapper {
  display: flex;
  align-items: center;
}

.cancelButton {
  margin-left: 12px;
  @include small {
    display: none;
  }
}

.rightSideButtons {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

@include large {
  .actionButton {
    min-width: 0 !important;

    .buttonText {
      display: none;
    }
  }
  .rightSideButtons {
    gap: 0;
  }
}
