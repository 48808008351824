@import 'styles/shared.scss';

.container {
  border-radius: 2px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  justify-content: center;
}

.name {
  max-height: 80px;
  text-align: center;
  z-index: 3;
  font-size: 12pt;
  color: $colorSecondary;
  overflow: hidden;
}

.vector {
  max-width: 100%;
  max-height: 100%;
}
