@import '../../styles/shared.scss';

.container {
  //height: $MediaBrowserHeaderHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $MainContentAreaTopMargin;
  @include medium {
    //height: 50px;
    padding: 0 14px;
    margin-top: 0;
  }
  min-height: 40px;
}

.rightSide {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.uploadIcon {
  padding: 0;
  background-color: transparent;
  margin-left: $MediaBrowserToolbarIconsMargin;
  margin-top: 3pt;

  @include medium {
    display: none;
  }
}

.pipe {
  margin-left: $MediaBrowserToolbarIconsMargin;
  width: 1pt;
  height: 19pt;
  border: solid 1pt $colorGreyMedium;
  @include medium {
    display: none;
  }
}

.gridOrListIcon {
  display: flex;
  justify-content: flex-end;
  width: 40px;
  background-color: transparent;
  padding: 0;
  margin-top: 4px;
  cursor: pointer;
  @include medium {
    display: none;
  }
}

.pathWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  // width: 100%;
  // white-space: nowrap;
  // overflow: auto;
}

.linkedPath,
.unlinkedPath {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 80px;
  font-size: 1rem;
  @media screen and (max-width: 768px) {
    max-width: 115px;
    font-size: 0.8rem;
  }
}

.unlinkedPath {
  color: $colorPrimary !important;
  cursor: default;
}

.linkedPath {
  color: $colorSecondary !important;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: $colorPrimary !important;
  }
}

.splitLine {
  color: $colorSecondary !important;
}

.menuPath {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  @media screen and (max-width: 768px) {
    font-size: 0.7rem;
  }
}
