$headerHeight: 50pt;
$pageMargin: 15pt;
$sidebarWidth: 190pt;

$MainContentAreaTopMargin: 17px;
$MainContentAreaSideMargin: 35pt;

// Media Browser
$MediaBrowserToolbarIconsMargin: 20pt;
$MediaBrowserNameWidth: 203px;
$MediaBrowserDateCreatedWidth: 118px;
$MediaBrowserSizeWidth: 76px;
$MediaBrowserSharedWidth: 301px;
$MediaBrowserActionsWidth: 76px;
$MediaBrowserCheckboxWidth: 48px;
$MediaBrowserRowHeight: 50px;
$MediaBrowserHeaderHeight: 40px;
$MediaBrowserGridItemThumbnailWidth: 180px;
$MediaBrowserGridItemThumbnailHeight: 114px;
$AssetsGridItemThumbnailHeight: 145px;
