.modal {
  .modalContent {
    @media screen and (max-width: 600px) {
      padding: 1rem !important;
    }

  }
  .dialogActions {
    background-color: #f4f4f4;
    padding: 0 1.5rem;
  }
}

.modalFooterActions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 32rem;
  margin: 1rem auto;
  background: transparent;

  :nth-child(2) {
    margin-left: 2rem;
  }

  .modalAction {
    padding: 0.5rem 3rem;
    flex: 1;
  }
}
