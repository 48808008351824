@import 'styles/shared.scss';

.container {
  height: $MediaBrowserRowHeight;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  border-bottom: solid 1px $colorGreyLight;
  background: $colorGreyLight;
  margin-top: 22px;
  @include medium {
    margin-top: 0;
  }
}

.nameWrapper,
.name,
.dateCreated,
.size,
.sharedWith,
.actions,
.checkbox {
  flex: 0 0 auto;
  color: #9e9e9e;
  font-size: 10pt;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.checkbox {
  flex-basis: $MediaBrowserCheckboxWidth;
  top: 3px;
}

.nameWrapper {
  flex-basis: $MediaBrowserNameWidth;
  flex: 1;
  padding: 0 1rem;
}

.dateCreated {
  flex-basis: $MediaBrowserDateCreatedWidth;
}

.size {
  flex-basis: $MediaBrowserSizeWidth;
}

.sharedWith {
  flex-basis: $MediaBrowserSharedWidth;
  flex: 1;
  max-width: 300px;
}

.dateCreated,
.size,
.sharedWith {
  @include medium {
    display: none;
  }
}
.actions {
  flex-basis: $MediaBrowserActionsWidth;
}
