.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .backBtn {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      font-size: 15px;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
  }
}
