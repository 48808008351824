@import '../../styles/shared.scss';

.container {
  position: relative;
  overflow: hidden;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 17px;
  }
}

.name {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}

.progressContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  .progress {
    flex: 1;
    height: 6px;
  }
}

.cancel {
  background: white;
  padding: 0;
  position: absolute;
  top: 15px;
  right: 12px;
  transform: scale(0.6);
}
