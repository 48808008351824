@import 'styles/shared.scss';

.container {
  height: $MediaBrowserRowHeight;
  display: flex;
  align-items: center;
  border-bottom: solid 1px $colorGreyLight;
  position: relative;

  &.modalView {
    padding: 0 15px;
  }
}

.name,
.dateCreated,
.size,
.sharedWith,
.checkbox {
  flex: 0 0 auto;
  font-size: 14px;
  color: #383838;
}

.checkbox {
  flex-basis: $MediaBrowserCheckboxWidth;
  top: 3px;
}

.nameWrapper {
  flex-basis: $MediaBrowserNameWidth;
  min-width: $MediaBrowserNameWidth;
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  padding: 0 1rem;
}

.name {
  margin-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
  font-weight: 400;
  cursor: pointer;
}

.folderName {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.dateCreated {
  flex-basis: $MediaBrowserDateCreatedWidth;
}

.size {
  flex-basis: $MediaBrowserSizeWidth;
}

.sharedWith {
  flex-basis: $MediaBrowserSharedWidth;
  flex: 1;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dateCreated,
.size,
.sharedWith {
  @include medium {
    display: none;
  }
}

.actions {

  &,
  &Clicked {
    position: absolute;
    right: 14px;
    width: 24px;
    height: 24px;
    top: 14px;
    background: $colorWhite;
    padding: 0;

    &:hover {
      cursor: pointer;
    }

    @include medium {
      cursor: pointer;

      &::before {
        content: '|';
        border-left: 1px solid $colorGreyLight;
        position: absolute;
        right: 30px;
        top: -5px;
        color: $colorWhite;
        height: 34px;
      }
    }
  }

  &Clicked {
    background: $colorGreyLight;
  }
}

.tooltipButton {
  color: $colorPrimary;
  font-size: 12px;
  line-height: 24px;
  background: $colorWhite;
  padding: 0;
  display: block;
  font-weight: normal;
  text-transform: none;
  cursor: pointer;
}

.moreBtn {
  margin-right: 1rem;
}

.actionsContainer, .actionsPlaceholder {
  width: $MediaBrowserActionsWidth;
  text-align: right;
}

.noFolder {
  pointer-events: none;

  label {
    opacity: 0.5;
  }
}
