@import "styles/shared.scss";

.container {
  width: 100%;
  height: auto;
}

.scrollContainer {
  padding-top: $headerHeight;
  margin-left: 0;
  width: 100%;

  @include desktop {
    margin-left: $sidebarWidth;
    width: calc(100% - $sidebarWidth);
  }

  @include medium {
    margin-left: 0;
    width: 100%;
  }

  transition: all $easeDuration $ease;
}
